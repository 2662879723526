import { Block, Container, Text } from '@actovos-consulting-group/ui-core';
import firebase from 'firebase/app';
import React from 'react';
import { useAsync } from 'react-use';
import NoContentMsg from '../../../no-content-msg';
import ProductCard from '../../../product-card';

const UserReviews = ({ user }) => {
  const { value: products } = useAsync(async () => {
    const { docs } = await firebase
      .firestore()
      .collectionGroup('RoastOrToast')
      .where('Roaster', '==', user.ref)
      .get();

    return Promise.all(
      docs.map(async doc => {
        const productDoc = await doc?.ref?.parent.parent.get();
        const { score } = doc.data();
        return { ...productDoc.data(), score, id: productDoc.id };
      }),
    );
  });

  const hasRoasts = products && products.length;

  return (
    <Block pb={40} mb={60}>
      <Container>
        <Text
          my={4}
          textAlign="center"
          fontWeight="bold"
          fontSize={{ _: 20, xs: 50 }}
        >
          {user.name}&lsquo;s Reviews
        </Text>
        {!hasRoasts ? (
          <NoContentMsg>
            <>
              <Text as="span" fontWeight="bold">
                {user.name}
              </Text>{' '}
              has not roasted or toasted any products yet
            </>
          </NoContentMsg>
        ) : (
          products?.map(product => (
            <ProductCard key={product.name} product={product} />
          ))
        )}
      </Container>
    </Block>
  );
};

export default UserReviews;
