import {
  Block,
  Button,
  Container,
  Flex,
  Text,
} from '@actovos-consulting-group/ui-core';
import { FiEdit2 } from '@react-icons/all-files/fi/FiEdit2';
import { Link } from 'gatsby';
import { truncate } from 'lodash';
import React from 'react';
import { useTheme } from 'styled-components';
import { useAuthContext } from '../../../../firebase/context';
import FirebaseImage from '../../../firebase-image';
import ToastLogo from '../../../svgs/toast';

const UserHero = ({ user: { isRoaster, id, name, bio } }) => {
  const { user } = useAuthContext();
  const theme = useTheme();
  const isCurrentUser = user.id === id;

  return (
    <Block py={40}>
      <Container>
        <Flex flexDirection={{ _: 'column', xs: 'row' }}>
          <Block mr={20}>
            <Block width={{ _: '40vw', xs: 200, md: 300 }} mb={10}>
              <FirebaseImage width="100%" src={`users/${id}/avatar.jpg`} />
            </Block>
            {isCurrentUser && (
              <Button as={Link} to="/app/edit-profile" leftIcon={FiEdit2}>
                Edit Profile
              </Button>
            )}
            {isRoaster && (
              <Flex alignItems="center" my={20}>
                <ToastLogo width={40} color={theme.colors.primary} />
                <Text fontWeight={500} ml={2}>
                  Product Roaster
                </Text>
              </Flex>
            )}
          </Block>
          <Block>
            <Text
              mt={{ _: 10, xs: 0 }}
              as="h2"
              fontSize={{ _: 30, xs: 50 }}
              fontWeight="bold"
            >
              {name}
            </Text>
            <Text fontWeight="bold">Who am I?</Text>
            <Text mt={20} style={{ whiteSpace: 'pre-wrap' }}>
              {truncate(bio, { length: 600 })}
            </Text>
          </Block>
        </Flex>
      </Container>
    </Block>
  );
};

export default UserHero;
