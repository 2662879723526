import { Block, Container, Text } from '@actovos-consulting-group/ui-core';
import firebase from 'firebase/app';
import React from 'react';
import { useAsync } from 'react-use';
import NoContentMsg from '../../../no-content-msg';
import CommentCard from './comment-card';

const CommentHistory = ({ user }) => {
  const { value: comments } = useAsync(async () => {
    const query = await firebase
      .firestore()
      .collectionGroup('Comments')
      .where('User', '==', user.ref)
      .get();

    return query.docs.map(doc => ({ ...doc.data(), doc, id: doc.id }));
  });

  const hasComments = comments && comments.length;

  return (
    <Container>
      <Text fontSize={30} my={20}>
        Comments History
      </Text>
      <Block mb={100}>
        {hasComments ? (
          comments.map(comment => (
            <CommentCard key={comment.id} comment={comment} />
          ))
        ) : (
          <NoContentMsg>
            {' '}
            <>
              <Text as="span" fontWeight="bold">
                {user.name}
              </Text>{' '}
              has not left any comments yet
            </>
          </NoContentMsg>
        )}
      </Block>
    </Container>
  );
};

export default CommentHistory;
