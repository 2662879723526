import React from 'react';
import Layout from '../../components/layout';
import UserProfilePage from '../../components/scenes/user-profile.js';

const UserProfile = ({ params: { slug } }) => {
  return (
    <Layout>
      <UserProfilePage slug={slug} />
    </Layout>
  );
};

export default UserProfile;
