import { Block, Container, Text } from '@actovos-consulting-group/ui-core';
import firebase from 'firebase/app';
import React from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import NoContentMsg from '../../../no-content-msg';
import ProductCard from '../../../product-card';

const UserProducts = ({ user }) => {
  const [products] = useCollectionData(
    firebase.firestore().collection('Products').where('User', '==', user.ref),
    {
      idField: 'id',
    },
  );
  const hasProducts = products && products.length;

  return (
    <Block bg="reallyLightGrey" pb={40} pt={10}>
      <Container>
        <Text
          my={4}
          textAlign="center"
          fontWeight="bold"
          fontSize={{ _: 20, xs: 50 }}
        >
          {user.name}&lsquo;s Products
        </Text>
        {!hasProducts ? (
          <NoContentMsg>
            <>
              <Text as="span" fontWeight="bold">
                {user.name}
              </Text>{' '}
              has not submitted any products yet
            </>
          </NoContentMsg>
        ) : (
          products?.map(product => (
            <ProductCard key={product.id} product={product} />
          ))
        )}
      </Container>
    </Block>
  );
};

export default UserProducts;
