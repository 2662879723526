import { Loadable } from '@actovos-consulting-group/ui-core';
import firebase from 'firebase/app';
import { navigate } from 'gatsby';
import React from 'react';
import { useAsync } from 'react-use';
import SEO from '../../seo';
import CommentHistory from './components/comment-history';
import UserHero from './components/hero';
import UserProducts from './components/user-products';
import UserReviews from './components/user-reviews';

const UserProfilePage = ({ slug }) => {
  const { loading, value: user } = useAsync(async () => {
    const query = await firebase
      .firestore()
      .collection('Users')
      .where('username', '==', slug)
      .get();

    if (!query.empty) {
      const doc = query.docs[0];
      return { ...doc.data(), id: doc.id, ref: doc.ref, doc };
    }

    return navigate('/');
  }, [slug]);

  return (
    <Loadable loading={loading || !user}>
      {user && <SEO title={user.name} description={user.bio} />}
      <UserHero user={user} />
      {user?.isRoaster && <UserReviews user={user} />}
      {user && <UserProducts user={user} />}
      {user && <CommentHistory user={user} />}
    </Loadable>
  );
};

export default UserProfilePage;
