import { Flex, Text } from '@actovos-consulting-group/ui-core';
import { ImSad2 } from '@react-icons/all-files/im/ImSad2';
import React from 'react';
import { useTheme } from 'styled-components';

const NoContentMsg = ({ children }) => {
  const theme = useTheme();
  return (
    <Flex justifyContent="center" alignItems="center">
      <ImSad2 color={theme.colors.primary} size={40} />
      <Text ml={20} fontSize={18}>
        {children}
      </Text>
    </Flex>
  );
};

export default NoContentMsg;
