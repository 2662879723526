import { Block, Card, Flex, Text } from '@actovos-consulting-group/ui-core';
import { Link } from 'gatsby';
import React from 'react';
import { useAsync } from 'react-use';
import Divide from '../../../divider';
import FirebaseImage from '../../../firebase-image';

const CommentCard = ({ comment }) => {
  const { value: parentProduct } = useAsync(async () => {
    const parent = await comment.doc.ref.parent.parent.get();
    return { ...parent.data(), id: parent.id };
  });

  if (!parentProduct) {
    return null;
  }

  return (
    <Card mb={3}>
      <Flex>
        <FirebaseImage
          src={`products/${parentProduct.id}/product/${parentProduct.productPhoto}`}
          width={100}
        />
        <Block ml={20}>
          <Link to={`/product/${parentProduct.slug}`}>
            <Text fontSize={20}>{parentProduct.name}</Text>
          </Link>
          <Text mt={10} fontSize={15}>
            {parentProduct.description}
          </Text>
          <Divide my={20} />
          <Text mt={2}>{comment.value}</Text>
        </Block>
      </Flex>
    </Card>
  );
};

export default CommentCard;
